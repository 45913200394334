@tailwind base;
@tailwind components;
@tailwind utilities;


*,
html {
  scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.754);
  border-radius: 5px;
}

/* .HomeBG {
  background: url(./backgroundImages/background.png);
} */


body {
  font-family: 'electrica';
  background-image: url(./assets/backgroundImages/backgroundMain.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}


@font-face {
  font-family: 'electrica';
  src: url(./assets/fonts/electrica-regular.ttf);
}

.float-animation {
  animation: float 1s ease-in-out infinite;
  filter: drop-shadow(30px 10px 4px gray);
}

@keyframes float {
  0% {
    filter: drop-shadow(30px 10px 4px gray);
    transform: translatey(0px);
  }

  50% {
    filter: drop-shadow(30px 10px 4px gray);
    transform: translatey(-20px);
  }

  100% {
    filter: drop-shadow(30px 10px 4px gray);
    transform: translatey(0px);
  }
}